import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {OrderDetailLine} from '../../../constants/propTypesDefinitions';
import {createFetchOrderRemoveItem, createFetchOrderCancel} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { ORDER_LINE_STATE } from '../../../constants/orderStates';

/**
 * @fero
 */

class RemoveOrderItem extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func.isRequired,
        orderDetailsLine: OrderDetailLine.isRequired,
        buttonClassName: PropTypes.string,
        isRemote: PropTypes.bool,
    };

    onFinish = () => {
        const {onFinish} = this.props;
        onFinish();
    };

    render() {
        const {orderDetailsLine, onFinish, buttonClassName, isRemote, ...props} = this.props;
        const disabled = isRemote || (orderDetailsLine.id_status == ORDER_LINE_STATE.FINISHED);
        return <FormModal
            {...props}
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Odstrániť položku v objednávke</Trans>}>
                    <Button
                        disabled={disabled}
                        size="small"
                        type="danger"
                        icon="delete"
                        className={buttonClassName ? buttonClassName : ''}
                    />
                </Tooltip>
            }
            values={{
                orderId: orderDetailsLine.id_order,
                itemId: orderDetailsLine.id_item
            }}
            onFinishSuccessful={this.onFinish}
            title={<Trans>Zrušenie položky v objednávke</Trans>}
            Form={ orderDetailsLine.id_status == ORDER_LINE_STATE.DRAFT ?
                generalNoParameterFormPC(
                    <Trans>Chcete odstrániť položku v objednávke?</Trans>,
                    createFetchOrderRemoveItem( orderDetailsLine.id_order, orderDetailsLine.id_item)
                ) :
                RemoveOrderItemForm
            }
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa zrušiť položku v objednávke.`)}
        />;
    }

}

export default RemoveOrderItem;

class RemoveOrderItemForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            orderId: PropTypes.number.isRequired,
            itemId: PropTypes.number.isRequired,
        }).isRequired,
    };

    onCancelButtonClick = () => {
        const {onSubmitFinish, values} = this.props;
        const {orderId, itemId} = values;
        createFetchOrderCancel(orderId, itemId)().then((response) => {
            onSubmitFinish(response);
        });
    }

    onRemoveButtonClick = () => {
        const {onSubmitFinish, values} = this.props;
        const {orderId, itemId} = values;
        createFetchOrderRemoveItem(orderId, itemId)().then((response) => {
            onSubmitFinish(response);
        });
    }

    render() {
        const {onClose} = this.props;

        return <div>
            <h5><Trans>Prajete si ukončiť alebo vymazať položku objednávky?</Trans></h5>
            <div className="mt-3 d-flex justify-content-end">
                <Button onClick={onClose}>
                    <Trans>Späť</Trans>
                </Button>
                <Button onClick={this.onCancelButtonClick} type="primary">
                    <Trans>Ukončiť</Trans>
                </Button>
                <Button onClick={this.onRemoveButtonClick} type="danger">
                    <Trans>Vymazať</Trans>
                </Button>
            </div>
        </div>;
    }
}
